import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const ls = new SecureLs({isCompression:false});

export default createStore({
  modules: {
  
  },
    
  // plugins: [createPersistedState(
  //   {
  //     key : 'www.covidassesment.org',
  //     paths: ['auth'],
  //     storage:{
  //        getItem : (key) => ls.get(key),
  //        setItem: (key, value) => ls.set(key, value),
  //        removeItem: (key) => ls.remove(key)
  //     }
  //   }
  // )],
  
})
