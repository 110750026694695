

let routes = [
    {
        path: "/",
        name: "home", 
        props:true,
        component: () => import("@/views/dash.vue"),
        title: ' Assesment of Covid 19 Response in Eastern, Central and West Africa',
        children:[

            {
                path: "/",
                name: "home", 
                props:true,
                component: () => import("@/views/home/welcome.vue"),
                meta: {
                title: 'Welcome '
                },
        

            },


            

            ///gloabs
            {
                path: "/more-about-project",
                name: "moreaboutproject", 
                props:true,
                component: () => import("@/views/globals/aboutproject.vue"),
                meta: {
                title: 'About The Project'
                },
        

            },


            {
                path: "/about-covid",
                name: "aboutcovid", 
                props:true,
                component: () => import("@/views/globals/aboutcovid.vue"),
                meta: {
                title: 'About Covid 19'
                },
        

            },


            {
                path: "/community-of-practice",
                name: "practice", 
                props:true,
                component: () => import("@/views/globals/practice.vue"),
                meta: {
                title: 'Community of Practice'
                },
        

            },

            {
                path: "/project-team",
                name: "projectteam", 
                props:true,
                component: () => import("@/views/globals/team.vue"),
                meta: {
                title: 'Project Team'
                },
        

            },

            {
                path: "/message-from-the-principal-investigator",
                name: "messagefromtheprincipalinvestigator", 
                props:true,
                component: () => import("@/views/globals/proncipalspeach.vue"),
                meta: {
                title: 'Message from the Principal Investigator'
                },
        

            },
            


            {
                path: "/information-tool",
                name: "informationtool", 
                props:true,
                component: () => import("@/views/globals/informationtool.vue"),
                meta: {
                title: 'Information Request Tool'
                },
        

            },




            //products
            //briefs
            {
                path: "/products/briefs",
                name: "briefs", 
                props:true,
                component: () => import("@/views/briefs/briefs.vue"),
                meta: {
                title: 'Briefs'
                },
            },



            //reports
            {
                path: "/products/reports",
                name: "reports", 
                props:true,
                component: () => import("@/views/reports/reports.vue"),
                meta: {
                title: 'Reports'
                },
            },



            //seminars
            {
                path: "/products/Webinars-and-Conferences",
                name: "seminars", 
                props:true,
                component: () => import("@/views/seminars/seminars.vue"),
                meta: {
                title: 'Seminars'
                },
            },


            //media
            //blogs
            {
                path: "/media/blogs",
                name: "blogs", 
                props:true,
                component: () => import("@/views/blogs/blogs.vue"),
                meta: {
                title: 'Blogs'
                },
            },



            //news
            {
                path: "/media/news",
                name: "news", 
                props:true,
                component: () => import("@/views/news/news.vue"),
                meta: {
                title: 'News'
                },
            },

            {
                path: "/media/news/MakSPH-leads-a-partnership-of-five-African-universities-to-generate-evidence-to-support-the-COVID-19-response",
                name: "maksphleadsapartnership", 
                props:true,
                component: () => import("@/views/news/maksphleadsapartnership.vue"),
                meta: {
                title: 'MakSPH leads a partnership of five African universities to generate evidence to support the COVID-19 response'
                },
            },


            {
                path: "/media/news/MakSPH-leads-a-partnership-of-five-African-universities-to-generate-evidence-to-support-the-COVID-19-response",
                name: "nitaulauch", 
                props:true,
                component: () => import("@/views/news/nitaulauch.vue"),
                meta: {
                title: 'MakSPH leads a partnership of five African universities to generate evidence to support the COVID-19 response'
                },
            },

            {
                path: "/media/news/World-Vision-Supports-Ghana-Health-Service-in-response-to-COVID-19",
                name: "wvghanahelp", 
                props:true,
                component: () => import("@/views/news/wvghana.vue"),
                meta: {
                title: 'World Vision Supports Ghana Health Service in response to COVID-19'
                },
            },

        ]
    },


  

]
export default routes;