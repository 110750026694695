<template>
<div style="zoom:90% !important">
  <router-view/>
</div>
</template>
<script>
export default {
  


  created(){

    var scripts = [
    "/assets/js/all.js",
    "/assets/js/custom.js",
    ];
    scripts.forEach((script) => {
      let tag = document.createElement("script");
      tag.setAttribute("src", script);
      document.head.appendChild(tag);
    })

    }
}
</script>